import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
//import { ConfigProvider } from 'vant';

const app = createApp(App);

app.config.globalProperties.$trackEvent = function(eventData) {
	console.log('~~~~~~~~~',eventData);
	// gtag('event', eventData.action, {
	// 	'event_category': eventData.category,
	// 	'event_label': eventData.label
	// });
};

app.use(router);
//app.use(ConfigProvider);
app.mount('#app');